import React from "react"
import SEO from 'components/SEO/seo'
import Kindergarten from "components/Textbooks/KG";
import Program from "components/ProgramOfTheTextboxs/ProgramOfTheTextboxs";
import MainTemplate from 'templates/MainTemplate'


const SPPs = () => (
  <MainTemplate>
     <SEO title="Podręczniki dla przedszkoli" />
  <Kindergarten />
  <Program/>
  
  </MainTemplate>
)

export default SPPs
