import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";

const StyledProgram = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding-top: 50px;
  background-color: ${({ theme }) => theme.body};
  font-family: ${({ theme }) => theme.font.family.Montserrat};
`;

const StyleWrapper = styled.div`
  width: 100%;
  max-width: 1070px;
  height: auto;
  margin-bottom: 30px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.15);
  ${({ theme }) => theme.media.tablet} {
    width: 90%;
    max-width: 1070px;
  }
  ${({ theme }) => theme.media.desktop} {
    width: 90%;
    max-width: 1070px;
    height: auto;
    margin-bottom: 30px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.15);
  }
`;
const StyledDiv = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: none;
  border-top: none;
  background-color: ${({ theme }) => theme.white};

  &:last-child {
    padding-bottom: 0px;
  }
`;
const StyledInner = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.7);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  ${({ theme }) => theme.media.desktop} {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.7);
    flex: 1;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);

    &:last-child {
      border-bottom: none;
    }
  }
`;

const StyleUp = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 15px;
  ${({ theme }) => theme.media.tablet} {
    width: auto;
    height: auto;
  }

  ${({ theme }) => theme.media.desktop} {
    width: auto;
    height: auto;
    margin-bottom: 15px;
  }
`;

const StyledLeft = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: center;
  height: auto;
  padding-bottom: 30px;

  ${({ theme }) => theme.media.phonetextbooks} {
    flex-direction: row;
  }
  ${({ theme }) => theme.media.tablet} {
    flex-direction: row;
  }
  ${({ theme }) => theme.media.desktop} {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: auto;
    padding-bottom: 30px;
  }
`;
// const StyledRight = styled.div`
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   padding-top: 30px;
//   padding-bottom: 30px;
//   position: relative;
//   border-top: 1px solid rgba(0, 0, 0, 0.15);
//   ${({ theme }) => theme.media.tablet} {
//     border-top: none;
//     width: 100%;
//     display: flex;
//     justify-content: flex-start;
//     align-items: flex-start;
//     padding-left: 2%;
//     flex-direction: row;
//   }
//   ${({ theme }) => theme.media.desktop} {
//     width: 33%;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     padding-top: 0px;
//     padding-left: 1%;
//     flex-direction: ${({ isOneLine }) => (isOneLine ? 'row' : 'column')};
//   }
// `

// const StyledPakiet = styled.div`
//   position: relative;
//   width: 60%;
//   min-width: 250px;
//   margin-left: 0%;
//   ${({ theme }) => theme.media.tablet} {
//     margin-left: 10%;
//     width: 70%;
//   }
//   ${({ theme }) => theme.media.desktop} {
//     margin-left: 0%;
//     width: 100%;
//   }
// `

const StyledTitile = styled.div`
  padding: 6px 18px;
  background-color: rgba(0, 0, 0, 0.1);
  font-weight: 400;
`;
const StyledH2 = styled.h2`
  font-size: ${({ theme }) => theme.font.size.xxxm};
  font-family: ${({ theme }) => theme.font.family.Montserrat};
  font-weight: 400;
  color: ${({ theme }) => theme.blackfont};
`;

const Img = styled.img`
  width: ${({ isLeft }) => (isLeft ? "200px" : "120px")};
  height: ${({ isLeft }) => (isLeft ? "280px" : "170px")};
  margin-right: 2em;
  margin-bottom: 10px;
`;

const H2 = styled.h2`
  color: ${({ isBlue }) =>
    isBlue ? ({ theme }) => theme.blue : ({ theme }) => theme.blackfont};
  font-size: ${({ theme }) => theme.font.size.xm};
  margin-top: ${({ isTop }) => (isTop ? "20px" : "5px")};
  margin-bottom: 10px;
  font-weight: 600;
  ${({ theme }) => theme.media.tablet} {
    font-size: ${({ theme }) => theme.font.size.m};
    width: auto;
    height: auto;
  }
  ${({ theme }) => theme.media.desktop} {
    color: ${({ isBlue }) =>
      isBlue ? ({ theme }) => theme.blue : ({ theme }) => theme.blackfont};
    font-size: ${({ theme }) => theme.font.size.m};
    margin-top: ${({ isTop }) => (isTop ? "25px" : "10px")};
    margin-bottom: 10px;
    font-weight: 600;
  }
`;
const H3 = styled.h3`
  width: 90%;
  color: ${({ isBlue }) =>
    isBlue ? ({ theme }) => theme.blue : ({ theme }) => theme.blackfont};
  font-size: ${({ theme }) => theme.font.size.xs};
  margin-top: ${({ isTop }) => (isTop ? "25px" : "10px")};
  margin-bottom: 10px;
  font-weight: bold;
  line-height: 1.4;
`;
const StyledP = styled.div`
  font-size: ${({ theme }) => theme.font.size.xs};
  color: ${({ theme }) => theme.blackfont};
  padding: 2px;
  line-height: 1.6;

  &:first-child {
    padding-top: 2px;
  }

  &:last-child {
    padding-bottom: 2px;
  }
`;

const Button = styled.button`
  background-color: ${({ theme }) => theme.fontlightblue};
  border: none;
  min-width: 150px;
  margin-top: 5px;
  border-radius: 5px;
  padding: 12px 16px;
  transition: 0.3s ease-in;

  ${({ theme }) => theme.media.desktop} {
    background-color: ${({ theme }) => theme.fontlightblue};
    margin: 10px 10px;
    min-width: 200px;
    border: none;
    border-radius: 5px;
    padding: 12px 16px;
    transition: 0.3s ease-in;

    &:hover {
      background-color: ${({ theme }) => theme.blue};
      text-decoration: none;
    }
  }
`;
const StyledA = styled.a`
  color: ${({ theme }) => theme.white};
  font-weight: 500;
  font-size: ${({ theme }) => theme.font.size.xs};
  transition: 0.3s ease-in;
  &:hover {
    color: ${({ theme }) => theme.white};
    text-decoration: none;
  }
`;
const StyledAimg = styled.a`
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;
const StyledIMG = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

// eslint-disable-next-line react/prop-types
const ProgramOfTheTextboxs = () => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsProgramOfTheTextbox {
        nodes {
          id
          title
          description
          leftsite {
            ... on DatoCmsImageprogram {
              id
              imageprogram {
                alt
                url
                filename
                customData
              }
            }
            ... on DatoCmsHeadtitleprogram {
              id
              headtitleprogram
            }
            ... on DatoCmsDescriptionprogram {
              id
              textcontent
            }
            ... on DatoCmsButtonprogram {
              id
              buttonlink
            }
          }
        }
      }
    }
  `);
  return (
    <StyledProgram id="program">
      <StyleWrapper>
        <StyledTitile>
          <StyledH2>Program dla przedszkoli</StyledH2>
        </StyledTitile>

        {data.allDatoCmsProgramOfTheTextbox.nodes.map((Program) => (
          <StyledDiv key={Program.id}>
            <StyleUp>
              <H2 isBlue>{Program.title}</H2>
              {Program.description && <H3>{Program.description}</H3>}
            </StyleUp>

            <StyledInner>
              <StyledLeft>
                <StyledIMG>
                  {Program.leftsite.map((item) => {
                    const itemKey = Object.keys(item)[1];

                    switch (itemKey) {
                      case "imageprogram":
                        return (
                          <StyledAimg key={item[itemKey].alt} target="_blank" href={item[itemKey].alt}>
                            <Img
                              isLeft
                              key={item.alt}
                              src={item[itemKey].customData.link}
                              alt={item[itemKey].filename}
                            />
                          </StyledAimg>
                        );
                      default:
                        return null;
                    }
                  })}
                </StyledIMG>

                <div>
                  {Program.leftsite.map((item) => {
                    const itemKey = Object.keys(item)[1];

                    switch (itemKey) {
                      case "headtitleprogram":
                        return (
                          <H3
                            key={item.id}
                            dangerouslySetInnerHTML={{
                              __html: item.headtitlebook,
                            }}
                          />
                        );
                      case "textcontent":
                        return (
                          <StyledP
                            key={item.id}
                            dangerouslySetInnerHTML={{
                              __html: item.textcontent,
                            }}
                          />
                        );

                      case "buttonlink":
                        return (
                          <Button key={item.id}>
                            <StyledA target="_blank" href={item.buttonlink}>
                              Pobierz
                            </StyledA>
                          </Button>
                        );
                      default:
                        return null;
                    }
                  })}
                </div>
              </StyledLeft>

              {/* <StyledRight /> */}
            </StyledInner>
          </StyledDiv>
        ))}
      </StyleWrapper>
    </StyledProgram>
  );
};
export default ProgramOfTheTextboxs;
