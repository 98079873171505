import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import Pakiet from "../Pakiet/Pakiet";
import formatMoney from '../../utils/formatMoney';
import {StyleWrapper ,StyledP, StyledLeftDescription ,StyledLeft} from 'src/components/Textbooks/JointStyles';

const StyledKindergarten = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding-top: 50px;
  background-color: ${({ theme }) => theme.body};
  font-family: ${({ theme }) => theme.font.family.Montserrat};
`;


const StyledDiv = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: none;
  border-top: none;
  background-color: ${({ theme }) => theme.white};

  &:last-child {
    padding-bottom: 0;   
  }
`;
const StyledInner = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.7);
  flex: 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  ${({ theme }) => theme.media.desktop} {
    flex-direction: row;
    border-bottom: ${({noBorder}) => noBorder ? 'none' : '1px solid rgba(0, 0, 0, 0.15)'};
  
  }
`;

const StyleUp = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 15px;
`;

const StyledRight = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  ${({ theme }) => theme.media.tablet} {
    border-top: none;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 2%;
    flex-direction: row;
  }
  ${({ theme }) => theme.media.desktop} {
    width: 33%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-left: 2%;
    flex-direction: column;
    padding-top: 0;
  }
`;

const StyledRightInner = styled.div`
  width: 90%;
  justify-content: center;
  display: flex;
  flex-direction: ${({ isOneLine }) => (isOneLine ? "row" : "row")};
  ${({ theme }) => theme.media.desktop} {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
`;

const StyledPakiet = styled.div`
  position: relative;
  width: 60%;
  min-width: 250px;
  margin-left: 0%;
  ${({ theme }) => theme.media.tablet} {
    margin-left: 10%;
    width: 70%;
  }
  ${({ theme }) => theme.media.desktop} {
    margin-left: 0%;
    width: 100%;
  }
`;

const StyledTitile = styled.div`
  padding: 6px 18px;
  background-color: rgba(0, 0, 0, 0.1);
  font-weight: 400;
`;
const StyledH2 = styled.h2`
  font-size: ${({ theme }) => theme.font.size.xxxm};
  font-family: ${({ theme }) => theme.font.family.Montserrat};
  font-weight: 400;
  color: ${({ theme }) => theme.blackfont};
`;

const Img = styled.img`
  width: ${({ isLeft }) => (isLeft ? "200px" : "120px")};
  height: ${({ isLeft }) => (isLeft ? "280px" : "170px")};
  margin-right: 1em;
  margin-bottom: 10px;
`;

const H2 = styled.h2`
  color: ${({ isBlue }) =>
    isBlue ? ({ theme }) => theme.blue : ({ theme }) => theme.blackfont};
  font-size: ${({ theme }) => theme.font.size.xm};
  margin-top: ${({ isTop }) => (isTop ? "20px" : "5px")};
  margin-bottom: 10px;
  font-weight: 600;
  ${({ theme }) => theme.media.desktop} {
    color: ${({ isBlue }) =>
      isBlue ? ({ theme }) => theme.blue : ({ theme }) => theme.blackfont};
    font-size: ${({ theme }) => theme.font.size.m};
    margin-top: ${({ isTop }) => (isTop ? "25px" : "10px")};
    margin-bottom: 10px;
    font-weight: 600;
  }
`;
const H3 = styled.h3`
  width: 90%;
  color: ${({ isBlue }) =>
    isBlue ? ({ theme }) => theme.blue : ({ theme }) => theme.blackfont};
  font-size: ${({ theme }) => theme.font.size.xs};
  margin-top: ${({ isTop }) => (isTop ? "25px" : "10px")};
  margin-bottom: 10px;
  font-weight: bold;
  line-height: 1.4;
`;

const Price = styled.h2`
  font-size: ${({ theme }) => theme.font.size.xm};
  color: rgba(246, 150, 0, 1);
  font-weight: 800;
`;

const Button = styled.button`
  background-color: ${({ theme }) => theme.fontlightblue};
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  transition: 0.3s ease-in;

  ${({ theme }) => theme.media.desktop} {
    background-color: ${({ theme }) => theme.fontlightblue};
    border: none;
    border-radius: 5px;
    padding: 8px 12px;
    transition: 0.3s ease-in;

    &:hover {
      background-color: ${({ theme }) => theme.blue};
      text-decoration: none;
    }
  }
`;
const StyledA = styled.a`
  color: ${({ theme }) => theme.white};
  font-weight: 400;
  font-size: ${({ theme }) => theme.font.size.xxs};
  transition: 0.3s ease-in;
  &:hover {
    color: ${({ theme }) => theme.white};
    text-decoration: none;
  }
`;
const DIV = styled.a`
  text-decoration: none;
  padding-right: 10px;
  &:hover {
    text-decoration: none;
  }
`;

// eslint-disable-next-line react/prop-types
const Kindergarten = () => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsKindergarten(sort: { fields: sort }) {
        nodes {
          id
          title
          description
          leftsite {
            ... on DatoCmsImgbook {
              id
              imgbook {
                customData
                title
                alt
              }
            }
            ... on DatoCmsTextcontent {
              id
              textcontent
            }
            ... on DatoCmsButtonbook {
              id
              buttonbook
            }
            ... on DatoCmsHeadtitlebook {
              id
              headtitlebook
            }
            ... on DatoCmsPrice {
              id
              price
            }
          }
          rightbook {
            ... on DatoCmsImgbookr {
              id
              imgbookr {
                title
                alt
                customData
              }
            }
            ... on DatoCmsTextcontentr {
              id
              textcontentr
            }
            ... on DatoCmsHeadtitlebookr {
              id
              headtitler
            }
            ... on DatoCmsButtonbookr {
              id
              buttonbookr
            }
            ... on DatoCmsPricebookr {
              id
              pricebookr
            }
          }
        }
      }
    }
  `);
  const Length = data.allDatoCmsKindergarten.nodes.length -1;
  return (
    <StyledKindergarten>
      <StyleWrapper>
        <StyledTitile>
          <StyledH2>Podręczniki dla przedszkoli</StyledH2>
        </StyledTitile>

        {data.allDatoCmsKindergarten.nodes.map((Kindergarte, i) => (
          <StyledDiv key={Kindergarte.id}>
            <StyleUp>
              <H2 isBlue>{Kindergarte.title}</H2>
              {Kindergarte.description && <H3>{Kindergarte.description}</H3>}
            </StyleUp>

            <StyledInner noBorder={Length === i}>
              <StyledLeft>
                <div>
                  {Kindergarte.leftsite.map((item) => {
                    const itemKey = Object.keys(item)[1];

                    switch (itemKey) {
                      case "imgbook":
                        return (
                          <DIV key={item[itemKey].alt} target="_blank" href={item[itemKey].alt}>
                            <Img
                              isLeft
                              key={item.id}
                              src={item[itemKey].customData.ownUrl}
                              alt={item[itemKey].title}
                            />
                          </DIV>
                        );
                      default:
                        return null;
                    }
                  })}
                </div>

                <StyledLeftDescription>
                  {Kindergarte.leftsite.map((item) => {
                    const itemKey = Object.keys(item)[1];

                    switch (itemKey) {
                      case "hheadtitlebook":
                        return (
                          <H3
                            key={item.id}
                            dangerouslySetInnerHTML={{
                              __html: item.headtitlebook,
                            }}
                          />
                        );
                      case "textcontent":
                        return (
                          <StyledP
                            key={item.id}
                            dangerouslySetInnerHTML={{
                              __html: item.textcontent,
                            }}
                          />
                        );

                      case "price":
                        return (
                          <Price
                            key={item.id}>{formatMoney(item.price.slice(0,-2))}
                          </Price>)

                      case "buttonbook":
                        return (
                          <Button key={item.id}>
                            <StyledA target="_blank" rel="noopener" href={item[itemKey]}>
                              Przejdz do sklepu
                            </StyledA>
                          </Button>
                        );
                      default:
                        return null;
                    }
                  })}
                </StyledLeftDescription>
              </StyledLeft>

              <StyledRight>
                <StyledRightInner>
                  <div>
                    {Kindergarte.rightbook.map((item) => {
                      const itemKey = Object.keys(item)[1];

                      switch (itemKey) {
                        case "imgbookr":
                          return (
                            <DIV key={item[itemKey].alt} target="_blank" href={item[itemKey].alt}>
                              <Img
                                key={item.id}
                                src={item[itemKey].customData.ownUrl}
                                alt={item[itemKey].title}
                              />
                            </DIV>
                          );
                        default:
                          return null;
                      }
                    })}
                  </div>

                  <div>
                    {Kindergarte.rightbook.map((item) => {
                      const itemKey = Object.keys(item)[1];

                      switch (itemKey) {
                        // case 'headtitler':
                        //   return <h1 key={item.id} dangerouslySetInnerHTML={{ __html: item.headtitler }} />;
                        case "textcontentr":
                          return (
                            <StyledP
                              key={item.id}
                              dangerouslySetInnerHTML={{
                                __html: item.textcontentr,
                              }}
                            />
                          );
                        case "buttonbookr":
                          return (
                            <Button key={item.id}>
                              <StyledA target="_blank" rel="noopener" href={item[itemKey]}>
                                Przejdz do sklepu
                              </StyledA>
                            </Button>
                          );
                        case "pricebookr":
                          return (
                            <Price
                              key={item.id} >{formatMoney(item.pricebookr.slice(0,-2))}</Price>
                          )

                        default:
                          return null;
                      }
                    })}
                  </div>
                </StyledRightInner>

                <StyledPakiet>
                  <Pakiet />
                </StyledPakiet>
              </StyledRight>
            </StyledInner>
          </StyledDiv>
        ))}
      </StyleWrapper>
    </StyledKindergarten>
  );
};
export default Kindergarten;
